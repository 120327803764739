.start-report {
  align-items: end;
  --background: white;
  --width: fit-content;
  --height: 350px;
  --border-radius: 20px 20px 0 0;
  box-shadow: 0px -15px 50px rgba(5, 35, 25, 0.5);
}

.calendar-modal {
  align-items: end;
  --background: white;
  --max-width: 390px;
  --width: 100%;
  --height: 660px;
  --border-radius: 20px 20px 0 0;
  box-shadow: 0px -15px 50px rgba(5, 35, 25, 0.5);
}

.calendar-pwa-modal {
  --background: white;
  --width: 340px;
  --height: 460px;
  --border-radius: 40px 40px 0 0;
  box-shadow: 0px -15px 50px rgba(5, 35, 25, 0.5);
}

:root {
  ion-modal {
    --background: transparent;
    background: transparent;
    --border-radius: 10px;
  }
}

.information {
  --width: 150px;
  --height: auto;
  --background: none;
  --box-shadow: none;
  .popover-viewport {
    div {
      border-radius: 8px !important;
      --background: var(--ion-color-secondary);
      color: white;
      font-size: 14px;
    }
  }
}

.end-report {
  --width: calc(100vw - 32px);
  --max-width: 600px;
  --max-height: 600px;
}

.navigation-settings {
  --width: calc(100vw - 32px);
  --max-width: 500px;
  --height: 660px;
}

.contact-modal {
  --height: 90vh;
  --border-radius: 8px;
}


.before-unload {
  --background: transparent;
  --width: calc(100vw - 32px);
  --max-width: 400px;
  --height: auto;
  --border-radius: 20px !important;
}

.show-error {
  --background: transparent;
  --box-shadow: none;
  --width: calc(100vw - 32px) !important;
  --max-width: 500px;
  --height: 240px;
  animation: error-message 3s ease-in-out both;
}

.progress-modal {
  --width: calc(100vw - 32px) !important;
  --max-width: 600px;
  --max-height: 200px;
}
