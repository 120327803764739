.delay-1 {
  animation-delay: .1s !important;
}

.delay-2 {
  animation-delay: .2s !important;
}

.delay-3 {
  animation-delay: .3s !important;
}

.delay-4 {
  animation-delay: .4s !important;
}

.delay-5 {
  animation-delay: .5s !important;
}

.delay-6 {
  animation-delay: .6s !important;
}

.delay-7 {
  animation-delay: .7s !important;
}

.delay-8 {
  animation-delay: .8s !important;
}

.delay-9 {
  animation-delay: .9s !important;
}

.delay-10 {
  animation-delay: 1s !important;
}

.delay-11 {
  animation-delay: 1.1s !important;
}

.delay-12 {
  animation-delay: 1.2s !important;
}

.delay-13 {
  animation-delay: 1.3s !important;
}

.delay-14 {
  animation-delay: 1.4s !important;
}

.delay-15 {
  animation-delay: 1.5s !important;
}

.delay-16 {
  animation-delay: 1.6s !important;
}

.delay-20 {
  animation-delay: 2s !important;
}

.delay-30 {
  animation-delay: 3s !important;
}

.delay-40 {
  animation-delay: 4s !important;
}




