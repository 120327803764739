.duration-1 {
  animation-duration: .1s !important;
}

.duration-2 {
  animation-duration: .2s !important;
}

.duration-3 {
  animation-duration: .3s !important;
}

.duration-4 {
  animation-duration: .4s !important;
}

.duration-5 {
  animation-duration: .5s !important;
}

.duration-6 {
  animation-duration: .6s !important;
}

.duration-7 {
  animation-duration: .7s !important;
}

.duration-8 {
  animation-duration: .8s !important;
}

.duration-9 {
  animation-duration: .9s !important;
}

.duration-10 {
  animation-duration: 1s !important;
}

.duration-11 {
  animation-duration: 1.1s !important;
}

.duration-12 {
  animation-duration: 1.2s !important;
}

.duration-13 {
  animation-duration: 1.3s !important;
}





