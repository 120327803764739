.top-25 {
  top: 25% !important;
}

.top-75 {
  top: 75% !important;
}

.start-25 {
  left: 25% !important;
}

.start-75 {
  left: 75% !important;
}

.end-25 {
  right: 25% !important;
}

.end-75 {
  right: 75% !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-75 {
  bottom: 75% !important;
}
