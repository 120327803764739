@keyframes slideInUp {
  0% {
    opacity: 0;
    top: 20px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.slideInUp {
  position: relative;
  opacity: 0;
  animation: slideInUp ease-out both;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    bottom: 20px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

.slideInDown {
  position: relative;
  opacity: 0;
  animation: slideInDown ease-out both;
}


@keyframes slideInRight {
  0% {
    opacity: 0;
    right: 20px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

.slideInRight {
  position: relative;
  opacity: 0;
  animation: slideInRight ease-out both;
}


@keyframes slideInLeft {
  0% {
    opacity: 0;
    left: 20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.slideInLeft {
  position: relative;
  opacity: 0;
  animation: slideInLeft ease-out both;
}

@keyframes error-message {
  0% {
    top: 50px;
    opacity: 0;
    backdrop-filter: blur(0);
  }
  20% {
    top: 0;
    opacity: 1;
    backdrop-filter: blur(15px);
  }
  80% {
    top: 0;
    opacity: 1;
    backdrop-filter: blur(15px);
  }
  100% {
    top: -50px;
    opacity: 0;
    backdrop-filter: blur(0);
  }
}
