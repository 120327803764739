.fs-8 {
  font-size: 10px;
  @media only screen and (min-width: 786px) {
    font-size: 14px;
  }
}

.fs-12 {
  font-size: clamp(17px, 3vw, 23px);
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: clamp(15px, 3vw, 23px);
}

.fs-20 {
  font-size: 20px !important;
}

.fs-25 {
  font-size: 23px;
  @media only screen and (max-width: 786px) {
    font-size: 18px;
  }
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

.fs-85 {
  font-size: 85px;
}
