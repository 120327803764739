@font-face {
  font-family: "quicksand";
  src: url("../assets/fonts/quicksand/quicksand.book-regular.otf")
    format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "quicksand";
  src: url("../assets/fonts/quicksand/quicksand.light-regular.otf")
    format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "quicksand";
  src: url("../assets/fonts/quicksand/quicksand.bold-regular.otf")
    format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "lato";
  src: url("../assets/fonts/lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "lato";
  src: url("../assets/fonts/lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "lato";
  src: url("../assets/fonts/lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/material-icons.woff2") format("woff2");
}

.quicksand-bold {
  --ion-font-family: "quicksand" !important;
  font-family: "quicksand" !important;
  font-weight: bold;
}

.quicksand {
  --ion-font-family: "quicksand" !important;
  font-family: "quicksand" !important;
  font-weight: 400;
}

.quicksand-light {
  --ion-font-family: "quicksand" !important;
  font-family: "quicksand" !important;
  font-weight: 300 !important;
}

.poppins {
  --ion-font-family: "poppins" !important;
  font-family: "poppins" !important;
  font-weight: 400;
}

.poppins-light {
  --ion-font-family: "poppins" !important;
  font-family: "poppins" !important;
  font-weight: 300 !important;
}

.lato {
  --ion-font-family: "lato" !important;
  font-family: "lato" !important;
  font-weight: 400;
}

.lato-light {
  --ion-font-family: "lato" !important;
  font-family: "lato" !important;
  font-weight: 300 !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
